import React, {FC, useCallback, useState} from 'react';
import TitleBar from "../../primitives/TitleBar";
import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {BoxGridLayout} from "../../layout/boxGridLayout";
import {DoctorDashboardQuery, DoctorDashboardResult} from "../../../module/call/graphql";
import CallBox from "../../../module/call/components/CallBox";
import Button from "../../primitives/Button";
import Plus from "../../icons/plus.svg";
import InvitationPanel from "../../../module/user/components/InvitationPanel";
import {useHistory} from "react-router";

const DoctorDashboard: FC = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const [invitationPanelOpened, setInvitationPanelOpened] = useState(false);

    const { data, loading, refetch  } = useQuery<DoctorDashboardResult>(DoctorDashboardQuery);

    const closeInvitationPanel = () => {
        setInvitationPanelOpened(false);
        refetch().then();
    }

    return (
        <>
            <TitleBar title={t('global.callDashboard')} >
                <Button onClick={() => setInvitationPanelOpened(true)} variant="primary">
                    <img src={Plus} alt="" />
                    <span>{t('global.planCall')}</span>
                </Button>
            </TitleBar>

            {!loading && data && data.plannedCalls.length > 0 && (
                <>
                    <TitleBar title={t('global.plannedCalls')} />
                    <BoxGridLayout>
                        {data.plannedCalls.map(item =>
                            <CallBox key={`planned_call_${item.call.id}`} onClick={(id) => history.push(`/call/${id}`)} callDetail={item} />
                        )}
                    </BoxGridLayout>
                </>
            )}

            {!loading && data && data.callHistory.length > 0 && (
                <>
                    <TitleBar title={t('global.callHistory')} />
                    <BoxGridLayout>
                        {data.callHistory.map((item) =>
                            <CallBox key={`history_${item.call.id}`}  callDetail={item} />
                        )}
                    </BoxGridLayout>
                </>
            )}

            {invitationPanelOpened && <InvitationPanel inviteType={"Patient"} allowNow={false} onClose={closeInvitationPanel}/>}
        </>)
}

export default DoctorDashboard;
