import { createGlobalStyle } from 'styled-components';

import RobotoRegular from './fonts/Roboto-Regular.woff';
import RobotoRegular2 from './fonts/Roboto-Regular.woff2';
import RobotoMedium from './fonts/Roboto-Medium.woff';
import RobotoMedium2 from './fonts/Roboto-Medium.woff2';
import RobotoBold from './fonts/Roboto-Bold.woff';
import RobotoBold2 from './fonts/Roboto-Bold.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('Roboto'),
        url(${RobotoRegular2}) format('woff2'),
        url(${RobotoRegular}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('Roboto'),
        url(${RobotoMedium2}) format('woff2'),
        url(${RobotoMedium}) format('woff');
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('Roboto'),
        url(${RobotoBold2}) format('woff2'),
        url(${RobotoBold}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
      
    body{
      color: #062443;
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      line-height: 1;
    }
`
