import React, { FC } from 'react';
import {
    DefaultTheme,
    ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import { Normalize } from 'styled-normalize'
import Font from './font.js'

const theme: DefaultTheme = {

}

export const ThemeProvider: FC = ({ children }) => (
    <StyledThemeProvider theme={theme}>
        <Normalize />
        <Font />
        {children}
    </StyledThemeProvider>
)
