import React, {FC, useEffect} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {MeQuery, MeResult} from "../module/user/graphql";
import Header from "../components/header";
import {Layout} from "../components/layout/appLayout";
import {Switch, useHistory, } from "react-router";
import CallPage from "./CallPage";
import {Route, Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import UserCircle from "../module/user/components/UserCircle";
import DoctorDashboard from "../components/dashboard/doctorDashboard";
import PatientDashboard from "../components/dashboard/patientDashboard";
import {twoChars} from "../tools/twoChars";
import Profile from "../components/profile/profile";
import DeclineCallPage from "../module/call/page/DeclineCall";
import useNotification from "../module/notifications/useNotification";
import UserName from "../module/user/components/UserName";

const AppPage: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { data, loading, error } = useQuery<MeResult>(MeQuery)
    const { initializeNotification } = useNotification();

    useEffect(() => {
        if(error){
            history.push('/logout');
        }
    }, [error])

    useEffect(() => {
        if(data && data.me){
            initializeNotification().then();
        }
    }, [data])

    if(data){
        return (
            <>
                <Header logoText={t(data.me.isDoctor? 'global.doctor' : 'global.patient')}>
                    {data.me && <UserName user={data.me}/>}
                </Header>
                <Layout>
                    <Switch>
                        {data.me.isDoctor && <Route path='/' exact={true} component={DoctorDashboard} />}
                        {!data.me.isDoctor && <Route path='/' exact={true} component={PatientDashboard} />}
                        <Route path='/call/:callId' component={CallPage}/>
                        <Route path='/decline/:callId' component={DeclineCallPage}/>
                        <Route path='/profile' component={Profile} />
                    </Switch>
                </Layout>
            </>
        );
    }else{
        return <span>Loading</span>
    }
}

export default AppPage;
