import React, {FC} from 'react';
import {ButtonBase} from "./styles";

type ButtonProps = {

}

const Button: FC<ButtonProps> = ({ children }) => {

    return <ButtonBase>{children}</ButtonBase>
}

export default Button;
