import React, {ChangeEvent, FC, InputHTMLAttributes, useCallback, useEffect, useState} from "react";
import {Container, Input, Label, Checkbox} from "./styles";
import {useField} from "react-final-form";

type OpeningHourFieldProps = {
    day: string;
    label: string;
};

const OpeningHourField: FC<OpeningHourFieldProps> = ({
    label, day
}) => {
    const enable = useField(`${day}Enable`, { type: 'checkbox',  })
    const opening = useField(`${day}Opening`, { type: 'time' })
    const closing = useField(`${day}Closing`, { type: 'time' })

    const [enabled, setEnabled] = useState(enable.input.checked);

    useEffect(() => {
        setEnabled(enable.input.checked);
    }, [enable])

    return (
        <Container>
            <Label>
                <Checkbox type='checkbox' {...enable.input}  />
                <span>{label}</span>
            </Label>

            {enabled && <Input type='time' {...opening.input} max={closing.input.value} />}
            {enabled && <Input type='time' {...closing.input} min={opening.input.value} />}
        </Container>
    )
}

export default OpeningHourField;
