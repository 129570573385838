import React, {FC, useCallback} from 'react';
import { Form } from 'react-final-form';
import {useTranslation} from "react-i18next";
import InputField from "../../../components/primitives/Form/InputField";
import Button from "../../../components/primitives/Form/Button";
import {useMutation, useQuery} from "@apollo/client";
import {MeQuery, MeResult, UpdateUser, UpdateUserResult} from "../graphql";
import {showFlashMessage} from "../../../components/FlashMessages";
import {BoxGrid2} from "../../../components/layout/boxGrid2";
import {BoxGrid3} from "../../../components/layout/boxGrid3";

type ProfileFormValues = {
    firstName: string,
    lastName: string,
    password: string
    newPassword: string
    newPasswordAgain: string
}

const ProfileForm: FC = () => {
    const { t } = useTranslation();

    const { data, refetch } = useQuery<MeResult>(MeQuery);
    const [update] = useMutation<UpdateUserResult>(UpdateUser)

    const handleSubmit = useCallback(async (input: ProfileFormValues) => {

        const result = await update({ variables: { input }});

        if(result){
            await refetch();
            showFlashMessage(t('global.messages.profileSaved'))
        }

    }, []);

    if(data){
        return (
            <Form<ProfileFormValues>
                initialValues={{
                    firstName: data.me.firstName,
                    lastName: data.me.lastName,
                    password: '',
                    newPassword: '',
                }}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>

                        <BoxGrid3>
                            <InputField label={t('global.firstName')} name='firstName' showLabel={true} />
                            <InputField label={t('global.lastName')} name='lastName' showLabel={true} />
                        </BoxGrid3>
                        <BoxGrid3 my={'8px'}>
                            <InputField label={t('global.actualPassword')} name='password' type='password' showLabel={true}/>
                            <InputField label={t('global.newPassword')} name='newPassword' type='password' showLabel={true}/>
                            <InputField label={t('global.newPasswordAgain')} name='newPasswordAgain' type='password' showLabel={true}/>
                        </BoxGrid3>

                        <Button>{t('global.save')}</Button>
                    </form>
                )}
            </Form>
        )
    } else {
        return <span>Loading</span>
    }
}

export default ProfileForm;
