import styled from 'styled-components';
import {MarginProps, margin, variant,} from "styled-system";

export type LogoVariants =  'fullWith'|'maxHeight'

export type LogoProps = {
    variant: LogoVariants
    maxSize?: number
}

export const Wrapper = styled.div<MarginProps & LogoProps>`
    ${margin};
    ${(props) => variant({
        variants: {
          fullWith:{
            display: 'flex',
            justifyContent: 'center'
          },
          maxHeight: {
            'maxHeight': '100%',            
          }
        }
    })}
`

export const Image = styled.img<LogoProps>`
    max-width: 100%;
    ${(props) => variant({       
        variants: {
          fullWith:{
              width: '100%',
          },
          maxHeight: {
              height: props.maxSize ? `${props.maxSize}px` : 'auto'
          }
        }
    })}  
`
