import React, { FC } from 'react';
import {gql} from "@apollo/client";

export type LoginActionInput = {
    email: string;
    password: string;
}

export type LoginActionResult = {
    login: {
        accessToken: string;
        refreshToken: string;
    }
}

export const LoginAction = gql`
    mutation login($input: LoginInput!) {
        login(input: $input) {
            accessToken
            refreshToken
        }
    }
`
