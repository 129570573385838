import React, {FC, useCallback, useState} from 'react';
import Panel, {Title, Text, BottomPart, Divider} from '../../../components/panel/';
import {useTranslation} from "react-i18next";
import InputField from "../../../components/primitives/Form/InputField";
import Button from "../../../components/primitives/Button";
import {Form} from 'react-final-form';
import {useMutation, useQuery} from "@apollo/client";
import {FilterUsers, FilterUsersQuery} from "../graphql";
import {CreateCall, CreateCallResult} from "../../call/graphql";
import DateTimeField from "../../../components/primitives/Form/DateTimeField";
import UserSearchResult from "./UserSearchResult";
import {showFlashMessage} from "../../../components/FlashMessages";
import styled from "styled-components";

const UserSearchWrapper = styled.div`
  overflow: auto;
  height: calc(100vh - 350px);
  @media (max-height: 575px) {
    height: auto;
  }
`

type InvitationPanelProps = {
    inviteType: 'Patient' | 'Doctor';
    allowNow?: boolean;
    onClose: () => void
}

type InvitationValues = {
    patientName: string;
    patientId: string;
    start: Date | null;
}

const InvitationPanel: FC<InvitationPanelProps> = ({
       inviteType,
       allowNow= false,
       onClose = () => {
       }
   }) => {
    const {t} = useTranslation();

    const [userName, setUserName] = useState('');
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null)
    const [date, setDate] = useState<string | null>(null)

    const {loading, data} = useQuery<FilterUsersQuery>(FilterUsers, {
        skip: userName.length < 3,
        variables: {
            filter: {
                type: inviteType,
                fullText: userName,
                place: 'Invitation'
            }
        }
    });
    const [createCall] = useMutation<CreateCallResult>(CreateCall);

    const handleSubmit = useCallback(async (input: InvitationValues) => {
        if(!allowNow && null && input.start){
            showFlashMessage(t('global.messages.pleaseSelectDate'), 'error');
        }

        if (selectedUserId) {
            const result = await createCall({
                variables: {
                    input: {
                        userId: selectedUserId,
                        start: input.start ? input.start : null
                    }
                }
            })

            if (result.data?.createCall) {
                showFlashMessage(t('global.messages.invitationCreated'));
                // todo flash message - hovor naplanovany
                onClose();
            } else {
                showFlashMessage(t('global.messages.invitationCreatedFailed'), 'error');
            }
        } else {
            showFlashMessage(t(`global.messages.pleaseSelect.${inviteType}`), 'error');
        }
    }, [selectedUserId]);

    return (
        <Panel onClose={onClose}>
            <Form<InvitationValues>
                initialValues={{}}
                onSubmit={handleSubmit}>
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <Title>{t('global.planCall')}</Title>
                        <InputField label={t(`global.search${inviteType}`)} name='userName' value={userName}
                                    onChange={(e) => setUserName(e.target.value)}/>

                        <UserSearchWrapper>
                          {!loading && data && data.filterUsers.length > 0 && (
                            data.filterUsers.map(user => <UserSearchResult key={`result_${user.id}`} isSelected={user.id === selectedUserId}
                                                                           select={(id) => setSelectedUserId(id)}
                                                                           user={user}/>)
                          )}
                        </UserSearchWrapper>

                        <BottomPart>
                            <Divider/>
                            <Text>{t(`global.whenYouWantToConnect.${inviteType}`)}</Text>
                            <DateTimeField name='start'/>
                            <Button variant="dark" size='full' height='bigger'
                                    disabled={!selectedUserId}>{t('global.sendInvitation')}</Button>
                        </BottomPart>

                    </form>
                )}
            </Form>
        </Panel>
    )
}

export default InvitationPanel;
