import React, { FC } from "react";
import { FullLayoutBackground } from "../module/auth/layout/loginLayout";
import LoginForm from "../module/auth/form/LoginForm";
import { LoginBox } from "../module/auth/components/box";

// import Logo from '../components/icons/zzmv.svg'
import Logo from '../components/primitives/Logo'

const LoginPage: FC = () => {
    return (
        <FullLayoutBackground>
            <LoginBox>
                {/*<img src={Logo} alt=""/>*/}
                <Logo variant='fullWith' bottomMargin={35}/>
                <LoginForm />
            </LoginBox>
        </FullLayoutBackground>
    );
}

export default LoginPage;
