import React, { FC } from 'react';
import styled from "styled-components";
import {User} from "../types";
import UserCircle from "./UserCircle";
import {twoChars} from "../../../tools/twoChars";
import {MarginProps, margin} from "styled-system";

const BoxWrapper = styled.div`
  
`

const BoxInner = styled.div`
    background-color: white;
    padding: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #062443;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    box-shadow: 0 2px 5px rgba(226, 235, 237, 0.61);
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
`

const Item = styled.div<MarginProps>`
    ${margin};
`

const SecondText = styled.span`
    opacity: 0.4;
    font-size: 13px;
    font-weight: 400;
`

type DoctorBoxProps = {
    user: Pick<User, 'id'|'fullName'|'firstName'|'lastName'|'profilePicture'|"doctor">
    onClick?: (id: string) => void
}

const UserBox: FC<DoctorBoxProps> = ({ user,onClick}) => {

    const handleClick = () => {
        if(onClick){
            onClick(user.id);
        }
    }

    return (
        <BoxWrapper onClick={handleClick}>
            <BoxInner>
                {user.doctor && user.doctor.city && <Item mb={15}><SecondText>{user.doctor.city}</SecondText></Item>}
                <Item mb={12}><UserCircle variant={'big'} twoChars={twoChars([user.firstName, user.lastName])} profilePicture={user.profilePicture} /></Item>
                <Item>{user.fullName}</Item>
                {user.doctor && user.doctor.category && <Item mt={'5px'}><SecondText>{user.doctor.category}</SecondText></Item>}
            </BoxInner>
        </BoxWrapper>
    )
}


export default UserBox;
