import {useCallback} from "react";
import {useCookies} from "react-cookie";
import {urlBase64ToUint8Array} from "../../tools/urlBase64ToUint8Array";
import {useConfiguration} from "../../configuration/useConfiguration";
import {useMutation} from "@apollo/client";
import {SubscribeNotifications} from "./graphql";
import {useCall} from "../call/useCall";
import {useTranslation} from "react-i18next";

export default function useNotification() {
    const { t } = useTranslation();
    const { webPushServerKey } = useConfiguration();
    const [subscribeNotification] = useMutation(SubscribeNotifications);
    const [cookies, setCookie, removeCookie] = useCookies();

    const initializeNotification = useCallback(async () => {
        try{
            let uniqueId = cookies.uniqueId;
            if(!uniqueId){
                uniqueId = Math.random().toString(36).substr(2, 9)
                setCookie('uniqueId', uniqueId, {
                    expires: new Date("2038-01-01 00:00:00")
                });
            }

            if(Notification.permission !== 'granted'){
                if(window.confirm(t('global.enablePermissions'))){
                    await window.Notification.requestPermission();
                }
            }

            if(Notification.permission === 'granted'){
                const serviceWorker = await navigator.serviceWorker.register(`./worker.js?v=1`);

                await window.navigator.serviceWorker.ready;

                if(serviceWorker){
                    let subscription = await serviceWorker.pushManager.getSubscription();

                    if(null === subscription){
                        subscription = await serviceWorker.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlBase64ToUint8Array(webPushServerKey)
                        });
                    }else{
                        console.log('Cant subscribe to service worker');
                    }

                    if(subscription){
                        await subscribeNotification({
                            variables: {
                                input: {
                                    uniqueId: uniqueId,
                                    payload: JSON.stringify(subscription.toJSON())
                                }
                            }
                        })
                    }else{
                        console.log('Nothing to subscribe');
                    }
                }
            }
        }catch(e){
            console.log('Cant register service worker for recieving notifications.', e);
        }
    }, [webPushServerKey, subscribeNotification]);

    const disablePushNotification = useCallback(async () => {

    }, [])

    return {
        initializeNotification,
        disablePushNotification
    }
}
