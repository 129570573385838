import React, { FC } from 'react';
import {useTranslation} from "react-i18next";
import {OpeningHours} from "../types";
import styled from "styled-components";

const DAYS = ['mo','tu','we','th','fr','sa','su'];

type OpeningHoursProps = {
    openingMonday: OpeningHours|null,
    openingTuesday: OpeningHours|null,
    openingWednesday: OpeningHours|null,
    openingThursday: OpeningHours|null,
    openingFriday: OpeningHours|null,
    openingSaturday: OpeningHours|null,
    openingSunday: OpeningHours|null,
}

type DayResult = {
    days: string[],
    opening: OpeningHours|null,
}

const Line = styled.p`
    
`

const OpeningHoursComponent: FC<OpeningHoursProps> = (props) => {
    const { t } = useTranslation();

    const dayResults: DayResult[] = [
        {
            days: ['mo'],
            opening: props.openingMonday,
        }
    ]

    const preparedDays = [
        {
            day: 'tu',
            opening: props.openingTuesday,
        },
        {
            day: 'we',
            opening: props.openingWednesday,
        },
        {
            day: 'th',
            opening: props.openingThursday,
        },
        {
            day: 'fr',
            opening: props.openingFriday,
        },
        {
            day: 'sa',
            opening: props.openingSaturday,
        },
        {
            day: 'su',
            opening: props.openingSunday,
        },
    ]

    for(let prepared of preparedDays){
        let found = false;
        const lastDayResult =  dayResults[dayResults.length - 1];
        const dayOpening = lastDayResult.opening ? lastDayResult.opening.toString() : null;
        const preparedOpening = prepared.opening ? prepared.opening.toString() : null;

        if(dayOpening === preparedOpening){
            dayResults[dayResults.length - 1].days.push(prepared.day)
            found = true;
        }

        if(!found){
            dayResults.push({
                days: [prepared.day],
                opening: prepared.opening,
            })
        }
    }

    const renderLine = (day: DayResult): string => {
        let line = '';

        const zeroPad = (num: number) => String(num).padStart(2, '0')

        if(day.days.length === 1){
            line = t(`global.days.short.${day.days[0]}`)
        }else{
            line = t(`global.days.short.${day.days[0]}`) + '-' + t(`global.days.short.${day.days[day.days.length - 1]}`)
        }

        if(day.opening){
            line += `: ${zeroPad(day.opening.openingHour)}:${zeroPad(day.opening.openingMinutes)} - ${zeroPad(day.opening.closingHour)}:${zeroPad(day.opening.closingMinutes)}`;
        }

        return line;
    }

    return (
        <>
            {dayResults
                .filter(item => !!item.opening)
                .map(item => (
                    <Line>{renderLine(item)}</Line>
            ))}
        </>)
}

export default OpeningHoursComponent;
