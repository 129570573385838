import styled, {keyframes} from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
`

export const OpeningAnimation = keyframes`
  from {right:-370px; opacity:0}
  to {right:0; opacity:1}
`

export const PanelWrapper = styled.div`
  position: relative;
  background-color: #1b5a9c;
  color: white;
  margin: auto 0 auto auto;
  padding: 0;
  width: 370px;
  height: 100%;
  animation-name: ${OpeningAnimation};
  animation-duration: 0.4s
`

export const PanelContainer = styled.div`
  height: calc(100% - 45px);
  padding: 45px 49px 0 49px;
  overflow: auto;
`

export const Close = styled.div`
  padding: 15px;
  width: 15px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  img {
    pointer-events: none;
    display: block;
  }
`
