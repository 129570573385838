import {gql} from "@apollo/client";
import {Doctor, User} from "../types";

export type MeResult = {
    me: Pick<User, 'id'|'email'|'firstName'|'lastName'|'fullName'|'isDoctor'|'profilePicture'>
}

export const MeQuery = gql`
    {
        me{
            id
            email
            firstName
            lastName
            fullName
            isDoctor
        }
    }
`;

export type DoctorProfileQuery = {
    doctorProfile: null|Pick<Doctor, 'category'|'city'|'office'|'openingMonday'|'openingTuesday'|'openingWednesday'|'openingThursday'|'openingFriday'|'openingSaturday'|'openingSunday'>
}

export const DoctorProfileQuery = gql`
    {
        doctorProfile{
            category
            city
            office
            openingMonday{
                openingHour
                openingMinutes
                closingHour
                closingMinutes
            }
            openingTuesday{
                openingHour
                openingMinutes
                closingHour
                closingMinutes
            }
            openingWednesday{
                openingHour
                openingMinutes
                closingHour
                closingMinutes
            }
            openingThursday{
                openingHour
                openingMinutes
                closingHour
                closingMinutes
            }
            openingFriday{
                openingHour
                openingMinutes
                closingHour
                closingMinutes
            }
            openingSaturday{
                openingHour
                openingMinutes
                closingHour
                closingMinutes
            }
            openingSunday{
                openingHour
                openingMinutes
                closingHour
                closingMinutes
            }
        }
    }
`


export type FilterUsersQuery = {
    filterUsers: Pick<User, 'id'|'fullName'|'firstName'|'lastName'|'profilePicture'>[]
}

export const FilterUsers = gql`
    query filterUsers($filter: Filter!){
        filterUsers(filter: $filter){
            id
            firstName
            lastName
            fullName
            profilePicture
            doctor {
                category
                city
            }
        }
    }
`

export type FindDoctorsQuery = {
    filterDoctors: Pick<User, 'id'|'firstName'|'lastName'|'fullName'|'profilePicture'|'doctor'>[]
}

export const FindDoctors = gql`
    query findDoctors($filter: FilterDoctors!){
        filterDoctors(filter: $filter){
            id
            firstName
            lastName
            fullName
            profilePicture
            doctor {
                category
                city
            }
        }
    }
`

export type UpdateUserResult = {

}

export const UpdateUser = gql`
    mutation updateUser($input: UpdateUser!){
        updateUser(input: $input) {
            id
            firstName
            lastName
        }
    }
`

export type UpdateDoctorResult = {

}

export const UpdateDoctor = gql`
    mutation updateDoctor($input: UpdateDoctorInput!){
        updateDoctor(input: $input) {
            id
        }
    }
`
