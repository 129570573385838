export default {
  logoText: 'Zdravotnické zařízení Ministerstva vnitra',
  callHistory: "Historie hovorů",
  plannedCalls: "Plánované",
  doctor: "Doktor",
  patient: "Pacient",
  office: "Ordinace:",
  openingHours: "Ordinační hodiny",
  ongoingCall: "Probíhající hovor",
  days: {
    short: {
      mo: "Po",
      tu: "Út",
      we: "St",
      th: "Čt",
      fr: "Pá",
      sa: "So",
      su: "Ne",
    },
    long: {
      mo: "Pondělí",
      tu: "Úterý",
      we: "Středa",
      th: "Čtvrtek",
      fr: "Pátek",
      sa: "Sobota",
      su: "Neděle",
    }
  },
  poweredBy: "Powered by:",
  hangOut: "Ukončit hovor",
  email: "E-mail",
  password: "Heslo",
  login: "Přihlásit se",
  resetPassword: "Obnovit heslo",
  onlineDoctors: "Doktoři online",
  reallyEndCall: "Ukončit hovor?",
  callStatus: {
    cantStart: "Nepovedlo se nastartovat kameru nebo mikrofon.",
    enableCameraAndMicrophone: "Povolte prosím kameru a mikrofón.",
    callStarting: "Hovor začíná",
    callCanceled: 'Hovor byl zrušen',
    tooEarly: 'Tento hovor je naplánovaný, možné ho bude začít až později.',
    alreadyMade: "Tento hovor již proběhl.",
    unauthorized: 'Tento hovor není určený Vám.',
    ended: 'Druhá strana ukončila hovor',
  },
  badUsernameOrPassword: 'Nesprávný email nebo heslo.',
  noDoctorOnline: 'Žádní doktoři nejsou dostupní v tuto chvíli.',
  profile: 'Profil',
  firstName: 'Jméno',
  lastName: 'Příjmení',
  save: 'Uložit',
  logout: 'Odhlásit se',
  doctorProfile: 'Nastavení doktora',
  category: 'Kategorie',
  city: 'Město',
  actualPassword: 'Aktuální heslo',
  newPassword: 'Nové heslo',
  newPasswordAgain: 'Nové heslo znovu',
  createCall: 'Vytvořit hovor',
  planCall: 'Naplánovat hovor',
  sendInvitation: 'Odeslat pozvánku',
  whenYouWantToConnect: {
    Doctor: 'Kdy se chcete s doktorem spojit?',
    Patient: 'Kdy se chcete s klientem spojit?',
  },
  callDashboard: 'Hovory',
  declineCall: 'Odmítnout hovor',
  reallyDeclineCall: 'Ano, odmítnout hovor',
  testNotification: 'Otestovat notifikace',
  searchPatient: 'Vyhledat pacienta',
  searchDoctor: 'Vyhledat doktora',
  messages: {
    profileSaved: 'Profil uložen',
    creatingCall: 'Inicializuji hovor...',
    cantCreateCall: 'Něco se rozbilo, nelze inicializovat hovor. Zkuste to prosím později.',
    invitationCreated: 'Pozvánka odeslána',
    invitationCreatedFailed: 'Vytvoření pozvánky selhalo.',
    pleaseSelectDate: 'Prosím, zvolte datum hovoru',
    pleaseSelect: {
      Doctor: 'Prosím, vyberte doktora se kterým se chcete spojit.',
      Patient: 'Prosím, vyberte pacienta se kterým se chcete spojit.',
    }
  },
  enablePermissions: 'Prosím povolte notifikace, prohlížeč Vám zobrazí oznámení o hovoru.',

};
