import React, { FC } from 'react';
import Logo from '../primitives/Logo';
import { Wrapper, Container, Column, Text, VerticalDivider } from './styles';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

type HeaderProps = {
    logoText?: string
}

const Header: FC<HeaderProps> = ({ logoText = undefined, children}) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container>
                <Column>
                    <Link to='/'>
                        <Logo variant={"maxHeight"} maxSize={35}/>
                    </Link>
                    <VerticalDivider/>
                    {logoText && <Text>{logoText}</Text>}
                </Column>
                <Column>{children}</Column>
            </Container>
        </Wrapper>
    );
}

export default Header;
