import styled from "styled-components";

export const LoginBox = styled.div`
    background-color: white;
    width: 100%;
    max-width: 332px;
    position: relative;
    margin: auto;
    padding: 45px 50px;
    border-radius: 18px;
    box-shadow: 0 5px 20px #e2ebed;
    box-sizing: border-box;
`
