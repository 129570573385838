import io from "socket.io-client";

type CallState =
  | "initialization"
  | "waiting-for-opponent"
  | "opponent-ready"
  | "connection"
  | "calling"
  | "closed";

export class CallSignalization {
  uri: string;

  callId: string;
  authToken: string;

  localMediaStream: MediaStream;
  remoteMediaStream: MediaStream | null = null;

  remoteMediaCallback: (mediaStream: MediaStream) => void;
  stateCallback: (newStatus: string) => void;

  isInitializer = false;
  isConnected = false;
  callState: CallState = "initialization";

  tokens: any | null = null;

  socket: SocketIOClient.Socket;
  peerConnection: RTCPeerConnection | null = null;

  localICECandidates: RTCIceCandidate[] = [];

  constructor(
    uri: string,
    callId: string,
    authToken: string,
    localMediaStream: MediaStream,
    remoteMediaCallback: (mediaStream: MediaStream) => void,
    stateCallback: (newStatus: string) => void,
  ) {
    this.uri = uri;
    this.callId = callId;
    this.authToken = authToken;
    this.localMediaStream = localMediaStream;

    this.remoteMediaCallback = remoteMediaCallback;
    this.stateCallback = stateCallback;

    this.socket = io(uri, {
      transports: ["websocket"],
      query: {
        token: this.authToken
      }
     });

    this.socket.on("message", (message: string) => {
      console.log("MESSAGE", message);
    });

    this.socket.on("token", (tokens: any) => {
      this.tokens = tokens;
      this.handleTokens(tokens).then();
    });

    this.socket.on("init-call", () => {
      console.log("Starting call");
      this.startCall().then();
    });

    this.socket.on("offer", (data: string) => {
      if (!this.isInitializer) {
        console.log("OFFER");
        this.responseOffer(data).then();
      } else {
        console.log("Ignoring offer as initializer");
      }
    });

    this.socket.on("answer", (data: string) => {
      if (this.isInitializer) {
        console.log("ANSWER");
        this.handleAnswer(data).then();
      } else {
        console.log("Ignoring answer as initializer");
      }
    });

    this.socket.on("candidate", (data: string) => {
      this.handleCandidate(data);
    });

    this.socket.on('ended', () => {
      this.stateCallback('ended')
    });

    this.socket.emit("join", this.callId);
  }

  private async handleTokens(tokens: any) {
    this.peerConnection = new RTCPeerConnection({
      iceServers: []//tokens.iceServers,
    });

    if (this.localMediaStream) {
      this.localMediaStream.getTracks().forEach((track: MediaStreamTrack) => {
        if (this.peerConnection) {
          this.peerConnection.addTrack(track, this.localMediaStream);
        }
      });
    }

    this.peerConnection.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      console.log("on ice candidate");
      if (event.candidate) {
        if (this.isConnected) {
          this.socket.emit(
            "candidate",
            JSON.stringify(event.candidate),
            this.callId,
          );
        } else {
          this.localICECandidates.push(event.candidate);
        }
      }
    };

    this.peerConnection.ontrack = (event: RTCTrackEvent) => {
      console.log("on track");
      this.remoteMediaStream = event.streams[0];
      this.isConnected = true;

      this.socket?.emit('starting', this.callId);

      this.remoteMediaCallback(event.streams[0]);
    };

    this.peerConnection.oniceconnectionstatechange = () => {
      if (this.peerConnection) {
        console.log(
          "iceConnectionState",
          this.peerConnection.iceConnectionState,
        );
        switch (this.peerConnection.iceConnectionState) {
          case "connected":
          case "disconnected":
        }
      }
    };
  }

  private async startCall() {
    if (this.peerConnection) {
      this.isInitializer = true;

      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);

      this.socket.emit("create-offer", JSON.stringify(offer), this.callId);
    } else {
      throw new Error("Cant start call - missing tokens and peerConnection");
    }
  }

  private async responseOffer(offer: string) {
    if (this.peerConnection) {
      const rtcOffer = new RTCSessionDescription(JSON.parse(offer));

      await this.peerConnection.setRemoteDescription(rtcOffer);

      const answer = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(answer);

      this.socket.emit("create-answer", JSON.stringify(answer), this.callId);
    } else {
      throw new Error(
        "Cant response offer - missing tokens and peerConnection",
      );
    }
  }

  private async handleAnswer(answer: string) {
    if (this.peerConnection) {
      const rtcAnswer = new RTCSessionDescription(JSON.parse(answer));

      await this.peerConnection.setRemoteDescription(rtcAnswer);

      this.localICECandidates.forEach((candidate) => {
        this.socket?.emit("candidate", JSON.stringify(candidate), this.callId);
      });

      this.localICECandidates = [];
    } else {
      throw new Error("Cant handle answer - missing tokens and peerConnection");
    }
  }

  private async handleCandidate(candidate: string) {
    if (this.peerConnection) {
      const rtcCandidate = new RTCIceCandidate(JSON.parse(candidate));
      await this.peerConnection.addIceCandidate(rtcCandidate);
    }
  }

  public async endCall() {
    console.log('CALLLLLLLLL ENDING', this.socket);
    if (this.socket) {
      await this.socket.emit('ending', this.callId);

      this.socket.close();
    }
  }

  public setAudioEnabled(enable: boolean): void {
    if (this.peerConnection) {
      const audioTrack: RTCRtpSender | undefined = this.peerConnection
          .getSenders()
          .find((s: RTCRtpSender) => s.track && s.track.kind === 'audio');


      if (audioTrack && audioTrack.track) {
        audioTrack.track.enabled = enable;

        console.log({audioTrack});
      }
    }
  }

  public setVideoEnable(enable: boolean): void {
    if (this.peerConnection) {
      const videoTrack: RTCRtpSender | undefined = this.peerConnection
          .getSenders()
          .find((s: RTCRtpSender) => s.track && s.track.kind === 'video');

      if (videoTrack && videoTrack.track) {
        videoTrack.track.enabled = enable;

        console.log({videoTrack});
      }
    }
  }
}
