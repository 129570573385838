import React, { FC } from 'react'
import {Wrapper, Image, LogoProps} from './style'
import logo from './zzmv.svg'

type Props = {
    bottomMargin?: number,
}

const Logo: FC<Props & LogoProps> = ({
    bottomMargin = 0,
    variant = 'fullWith',
    maxSize= undefined
                             }) => {
    return (
        <Wrapper marginBottom={`${bottomMargin}px`} variant={variant} maxSize={maxSize}>
            <Image src={logo} variant={variant} maxSize={maxSize}/>
        </Wrapper>
    )
}

export default Logo;
