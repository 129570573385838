import styled from "styled-components";

export const ButtonBase = styled.button`
  border-radius: 25px;
  height: 39px;
  border: none;
  background: #1B5A9C;
  font-weight: 500;
  color: white;
  padding: 0 20px;
  font-size: 13px;
  cursor: pointer;
`
