import React, { FC } from 'react';
import styled from "styled-components";
import {backgroundColor, BackgroundColorProps, variant} from "styled-system";

type CircleVariant = 'normal'|'big'

const colors: Record<string,string> = {
    a:'#F6A7C1',
    b:'#FDCF76',
    c:'#AAC5E2',
    d:'#97F2F3',
    e:'#C1CD97',
    f:'#E18D96',
    g:'#38908F',
    h:'#ECBE7A',
    i:'#9DABDD',
    j:'#AB6393',
    k:'#9C8ADE',
    l:'#9DABDD',
    m:'#FDDDDE',
    n:'#A6C7EA',
    o:'#86B7D9',
    p:'#9DABDD',
    q:'#E0E98D',
    r:'#B5B3DA',
    s:'#F6F7B9',
    t:'#F4D5D8',
    u:'#EC93BC',
    v:'#C392C0',
    w:'#AAE8E8',
    x:'#6AC3C3',
    y:'#CBD4D1',
    z:'#A0CECE',
}

type CircleProps = {
    variant: CircleVariant
}

type UserCircleProps = {
    twoChars: string,
    profilePicture: string|null
    variant?: CircleVariant
    onClick?: () => void
}

const CircleText = styled.div<CircleProps & {color: string}>`
  border-radius: 50%;
  color: white;
  border: 2px solid ${(props) => props.color};
  background-color: ${(props) => props.color};
  text-align: center;
  flex: 0 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  @media (max-width: 479px) {
    ${() => variant({
      variants: {
        normal: {
          width: '26px',
          height: '26px',
          'font-size': '16px',
          'line-height': '26px'
        },
        big: {
          width: '80px',
          height: '80px',
          'font-size': '40px',
          'line-height': '80px'
        }
      }
    })}
  }
  
  ${() => variant({
    variants: {
      normal: {
        width: '32px',
        height: '32px',
        'font-size': '20px',
        'line-height': '32px'
      },
      big: {
        width: '80px',
        height: '80px',
        'font-size': '40px',
        'line-height': '80px'
      }
    }
  })}
`
const CircleImage = styled.img<CircleProps>`
  border-radius: 50%;
  @media (max-width: 479px) {
    ${() => variant({
      variants: {
        normal: {
          width: '26px',
          height: '26px'
        },
        big: {
          width: '80px',
          height: '80px'
        }
      }
    })}
  }
  ${() => variant({
    variants: {
      normal: {
        width: '32px',
        height: '32px'
      },
      big: {
        width: '80px',
        height: '80px'
      }
    }
  })}
`


const UserCircle: FC<UserCircleProps> = ({ twoChars, profilePicture, variant = 'normal', onClick= () => {}}) => {

    const oneChar = twoChars.normalize("NFD").replace(/[\u0300-\u036f]/g, "")[0].toLowerCase();

    return profilePicture ?
        <CircleImage onClick={onClick} variant={variant} src={profilePicture} /> :
        <CircleText  onClick={onClick} variant={variant} color={colors[oneChar]}>{twoChars}</CircleText>
}

export default UserCircle;
