import {gql} from "@apollo/client";
import {Call, CallDetail} from "./types";
import {User} from "../user/types";

export type CreateCallInput = {

}

export type CreateCallResult = {
    createCall: {
        call: Pick<Call, 'id'>
    }
}

export const CreateCall = gql`
    mutation createCall($input: CreateCallInput!) {
        createCall(input: $input) {
            call {
                id
            }
        }
    }
`

export type DoctorDashboardResult = {
    plannedCalls: CallDetail[]
    callHistory: CallDetail[]
}

export const DoctorDashboardQuery = gql`
    {
        callHistory {
            call{
                id
                started
                duration
            }            
            opponent{
                id
                fullName
                firstName
                lastName
                profilePicture
            }
        }
        plannedCalls {
            call{
                id
                started
            }
            opponent{
                id
                fullName
                firstName
                lastName
                profilePicture
            }
        }
    }
`

export type CallDetailResult = {
    callDetail: {
        canBeStarted: boolean,
        reason: string,
        call: Pick<Call, 'id'|'started'>,
        opponent: Pick<User, 'id'|'firstName'|'lastName'|'fullName'|'profilePicture'|'doctor'>
    }
}

export const CallDetailQuery = gql`
    query callDetail($id: GlobalId!){
        callDetail(id: $id) {
            canBeStarted
            reason
            call{
                id
                state
                started
            }
            opponent{
                id
                firstName
                lastName
                fullName
                profilePicture
                doctor {
                    category
                    city
                    office
                    openingMonday {openingHour openingMinutes closingHour closingMinutes}
                    openingTuesday {openingHour openingMinutes closingHour closingMinutes}
                    openingWednesday {openingHour openingMinutes closingHour closingMinutes}
                    openingThursday {openingHour openingMinutes closingHour closingMinutes}
                    openingFriday {openingHour openingMinutes closingHour closingMinutes}
                    openingSaturday {openingHour openingMinutes closingHour closingMinutes}
                    openingSunday {openingHour openingMinutes closingHour closingMinutes}
                }
            }
        }
    }
`

export const DeclineCall = gql`
    mutation declineCall($id: GlobalId!) {
        declineCall(id: $id)
    }
`

export type PlannedCallsResult = {
    plannedCalls: CallDetail[]
}

export const PlannedCallsQuery = gql`
    {
        plannedCalls {
            call{
                id
                started
            }
            opponent{
                id
                fullName
                firstName
                lastName
                profilePicture
            }
        }
    }    
`
