import React, { ButtonHTMLAttributes, FC } from "react";
import styled, {css} from "styled-components";
import { variant, VariantArgs } from "styled-system";

type ButtonVariant = "primary" | "danger"| "dark";
type ButtonSize = "normal" | "full";
type ButtonHeight = "normal" | "bigger";

type ButtonProps = {
  variant: ButtonVariant;
  size: ButtonSize
  height: ButtonHeight
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonBase = styled.button<ButtonProps>`
  height: 39px;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 13px;
  img {
    margin-right: 8px;
  }
  ${() => variant({
    prop: 'height',
    variants: {
      bigger: {
        height: '49px',
        borderRadius: '24.5px'
      }
    }
  })}
  ${() => variant({
    prop: 'size',
    variants: {
      full: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  })}
  ${() =>
    variant({
      variants: {
        primary: {
          color: "white",
          background: "#1b5a9c",
        },
        danger: {
          color: "white",
          background: "#de0942",
        },
        dark: {
          color: "white",
          background: '#05213e'
        }
      },
    })}
`;

const Button: FC<Partial<ButtonProps>> = ({
  children,
  variant = "primary",
    size  = "normal",
    height  = "normal",
  ...rest
}) => {
  return (
    <ButtonBase variant={variant} size={size} height={height} onClick={rest.onClick}>
      {children}
    </ButtonBase>
  );
};
export default Button;
