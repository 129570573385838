import React, {FC} from "react";
import UserCircle from "./UserCircle";
import {twoChars} from "../../../tools/twoChars";
import styled from "styled-components";
import {User} from "../types";

type IsSelected = {
    selected: boolean;
}

type UserSearchResultProps = {
    isSelected: boolean,
    select: (id: string) => void
    user: Pick<User, 'id'|'fullName'|'firstName'|'lastName'|'profilePicture'>
}

const ResultWrapper = styled.div<IsSelected>`
  height: 49px;
  border-radius: 24.5px;
  background: rgba(255,255,255,0.21);
  background: ${(props) => (props.selected ? 'rgba(255,255,255,0.5)' : 'rgba(255,255,255,0.21)')};;
  display: flex;
  padding-left: 7px;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`

const Text = styled.span`
    margin-left: 10px;
`


const UserSearchResult: FC<UserSearchResultProps> = ({
                                                         isSelected, select, user
                                                     }) => {
    return (
        <ResultWrapper selected={isSelected} onClick={() => select(user.id)}>
            <UserCircle twoChars={twoChars([user.firstName, user.lastName])} profilePicture={user.profilePicture} />
            <Text>{user.fullName}</Text>
        </ResultWrapper>
    )
}

export default UserSearchResult;
