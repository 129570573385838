import React, {FC, useEffect, useState} from 'react';
import {Redirect, useHistory} from "react-router";
import {useApolloClient} from "@apollo/client";

const LogoutPage: FC = () => {
    const { clearStore, resetStore } = useApolloClient()
    const history = useHistory();

    useEffect(() => {
        const reset = async () => {
            try{
                await clearStore();
            }catch(e){

            }

            try{
                await resetStore();
            }catch(e){

            }

            history.push('/login');
        }
        reset().then();
    }, [])

    return <></>
}

export default LogoutPage;
