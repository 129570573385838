import styled from "styled-components";
import {MarginProps, margin} from "styled-system";

export const BoxGrid3 = styled.div<MarginProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0 16px;
  ${margin};
  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 479px) {
    grid-template-columns: 1fr;;
  }
`
