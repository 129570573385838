import React, {FC} from 'react';
import styled from "styled-components";

type TitleBarProps = {
    title: string
    subTitle?: string
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 16px;
  margin: 32px 0;
`

const Column = styled.div`
  line-height: 39px;
  display: flex;
  align-items: center;
`

const Title = styled.h1`
  display: inline;
  font-size: 20px;
  margin: 0;
`

const SubTitle = styled.span`
  font-size: 20px;
  font-weight: 400;
  margin-left: 8px;
`


const TitleBar: FC<TitleBarProps> = ({title, subTitle, children}) => {

    return (
        <Wrapper>
            <Column>
              <Title>{title}</Title>
              {subTitle && <SubTitle>{subTitle}</SubTitle>}
            </Column>
            <Column>{children}</Column>
        </Wrapper>
    );
}

export default TitleBar;
