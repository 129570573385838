import React, {FC, MouseEvent, useState} from 'react';

import { Wrapper, PanelWrapper, PanelContainer, Close } from "./styles";
import iconClose from "../icons/close.svg";

type PanelProps = {
    onClose: () => void
}

const Panel: FC<PanelProps> = ({ onClose = () => {}, children }) => {

    const closeClick = (e: MouseEvent<HTMLDivElement>) => {
        if(e.target === e.currentTarget) {
            onClose();
        }
    }

    return (
        <Wrapper onClick={closeClick}>
            <PanelWrapper>
                <Close onClick={closeClick}>
                    <img src={iconClose} alt="" />
                </Close>
                <PanelContainer>
                    {children}
                </PanelContainer>
            </PanelWrapper>
        </Wrapper>
    );
}

export default Panel;
