import styled from "styled-components";
import { WidthProps } from "styled-system";

export const Container = styled.div`
  margin-top: 8px;
  height: 39px;
  display: flex;
  align-items: center;
  &:first-child {
    margin-top: 0;
  }
`

export const Label = styled.label`
  padding: 12px 20px 12px 0;
  display: inline-flex;
  align-items: center;
  grid-gap: 8px;
  font-size: 13px;
  cursor: pointer;
  width: 100px;
`

export const Input = styled.input<WidthProps>`
  background: #EBF0F1;
  border: none;
  border-radius: 25px;
  height: 39px;
  color: #062443;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  &::placeholder {
    color: #062443;
    font-size: 14px;
  }
`
export const Checkbox = styled.input<WidthProps>`
  
`