import React, { FC } from 'react';
import { LayoutWrapper, Content } from './styles';


export const Layout: FC = ({ children }) => {
    return (
        <LayoutWrapper>
            <Content>
                {children}
            </Content>
        </LayoutWrapper>
    );
}
