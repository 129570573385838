import React, {FC, useCallback, useEffect} from 'react';
import TitleBar from "../../../components/primitives/TitleBar";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@apollo/client";
import {CallDetailQuery, CallDetailResult, DeclineCall} from "../graphql";
import Button from "../../../components/primitives/Button";
import {useHistory, useParams} from "react-router";

type Params = {
    callId: string;
};

const DeclineCallPage: FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [declineCall] = useMutation(DeclineCall);

    const { callId } = useParams<Params>();

    const { data, loading } = useQuery<CallDetailResult>(CallDetailQuery, {
        variables: {
            id: callId,
        },
    });

    useEffect(() => {

    }, [data]);

    const handleClick = useCallback(async () => {
       const result = await declineCall({
            variables: {
                id: callId
            }
        })

        // todo ADD flash messages

        history.push('/');
    }, []);

    return (
        <>
            <TitleBar title={t('global.declineCall')} />


            <Button variant={'danger'} onClick={handleClick}>{t('global.reallyDeclineCall')}</Button>
        </>
    )
}

export default DeclineCallPage;
