import React, { FC, useState } from "react";
import styled from "styled-components";

type ToggleButtonProps = {
  initValue?: boolean;
  change?: (newValue: boolean) => void;
};

type ButtonProps = {
  active: boolean;
};

const Button = styled.button<ButtonProps>`
  width: 39px;
  height: 39px;
  border-radius: 100%;
  border: none;
  background: #e5ecec;
  color: ${(props) => (props.active ? "#223c58" : "#95a1ae")};
  margin-right: 10px;
  cursor: pointer;
`;

const ToggleButton: FC<ToggleButtonProps> = ({
  children,
  initValue = false,
  change = () => {},
}) => {
  const [state, setState] = useState(initValue);

  const handleClick = () => {
    setState(!state);

    change(state);
  };

  return (
    <Button active={state} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default ToggleButton;
