import React, {FC, ReactNode} from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
`

const Title = styled.p`
  font-weight: normal;
  font-size: 13px;
  text-align: left;
  color: #062443;
  opacity: 0.4;
`

const Content = styled.div`
  font-weight: normal;
  font-size: 13px;
  text-align: left;
  color: #062443;
`

type InfoBoxProps = {
    title: string
    content: ReactNode|string
}

const InfoBox: FC<InfoBoxProps> = ({ title, content }) => {

    return (
        <Wrapper>
            <Title>{title}</Title>
            <Content>{content}</Content>
        </Wrapper>
    )
}

export default InfoBox;
