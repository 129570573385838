import React, {FC} from 'react';
import {ThemeProvider} from "./theme/theme";
import {Switch} from 'react-router';
import {ApolloProvider} from "@apollo/client";
import {CoreApolloLink} from "./apollo/client";
import {Route} from "react-router-dom";
import LoginPage from "./view/LoginPage";
import LogoutPage from "./view/LogoutPage";
import AppPage from "./view/AppPage";
import Moment from "react-moment";
import {FlashMessages} from "./components/FlashMessages";

Moment.globalFormat = 'DD. MM. YYYY HH:mm';

const App: FC = () => {
    return (
        <ThemeProvider>
            <ApolloProvider client={CoreApolloLink}>
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/logout" component={LogoutPage} />
                    <Route path='/' component={AppPage}/>
                </Switch>
                <FlashMessages/>
            </ApolloProvider>
        </ThemeProvider>
    )
}

export default App;
