import React, {FC, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@apollo/client";
import {DoctorProfileQuery, UpdateDoctor, UpdateDoctorResult} from "../graphql";
import { Form } from 'react-final-form';
import Button from "../../../components/primitives/Form/Button";
import InputField from "../../../components/primitives/Form/InputField";
import TitleBar from "../../../components/primitives/TitleBar";
import OpeningHourField from "../../../components/primitives/Form/OpeningHourField";
import {showFlashMessage} from "../../../components/FlashMessages";
import { BoxGrid4 } from "../../../components/layout/boxGrid4";
import { BoxGrid3 } from "../../../components/layout/boxGrid3";
import { BoxGrid2 } from "../../../components/layout/boxGrid2";
import { BoxGrid } from "../../../components/layout/boxGrid";

type DoctorFormValues = {
    office: string;
    category: string;
    city: string;

    mondayEnable: boolean;
    tuesdayEnable: boolean;
    wednesdayEnable: boolean;
    thursdayEnable: boolean;
    fridayEnable: boolean;
    saturdayEnable: boolean;
    sundayEnable: boolean;

    mondayOpening: string;
    mondayClosing: string;
    tuesdayOpening: string;
    tuesdayClosing: string;
    wednesdayOpening: string;
    wednesdayClosing: string;
    thursdayOpening: string;
    thursdayClosing: string;
    fridayOpening: string;
    fridayClosing: string;
    saturdayOpening: string;
    saturdayClosing: string;
    sundayOpening: string;
    sundayClosing: string;
}

const days = ['monday','tuesday','wednesday','thursday','friday','saturday','sunday']

const zeroPad = (num: number) => String(num).padStart(2, '0')
const ucFirst = (value: string) => value.charAt(0).toUpperCase() + value.slice(1)

const createInitValue = (day: string, doctor: any): object => {
    const key = `opening${ucFirst(day)}`;
    if(doctor[key]){
        return {
            [`${day}Enable`]: true,
            [`${day}Opening`]: `${zeroPad(doctor[key].openingHour)}:${zeroPad(doctor[key].openingMinutes)}`,
            [`${day}Closing`]: `${zeroPad(doctor[key].closingHour)}:${zeroPad(doctor[key].closingMinutes)}`,
        }
    }

    return {
        [`${day}Enable`]: false,
        [`${day}Opening`]: '08:00',
        [`${day}Closing`]: '18:00',
    }
}

const createValue = (day: string, values: any): object => {
    const key = `opening${ucFirst(day)}`;

    if(values[`${day}Enable`]){
        const opening = values[`${day}Opening`].split(':')
        const closing = values[`${day}Closing`].split(':')

        return {
            [key]: {
                openingHour: parseInt(opening[0]),
                openingMinutes: parseInt(opening[1]),
                closingHour: parseInt(closing[0]),
                closingMinutes: parseInt(closing[1]),
            }
        }
    }

    return {
        [key]: null
    }
}

const DoctorForm: FC = () => {
    const { t } = useTranslation();

    const { data, loading, refetch } = useQuery<DoctorProfileQuery>(DoctorProfileQuery);
    const [update] = useMutation<UpdateDoctorResult>(UpdateDoctor);

    const handleSubmit = useCallback(async (input: DoctorFormValues) => {
        const variables = {
            input: {
                office: input.office,
                category: input.category,
                city: input.city,
                // @ts-ignore
                ...Object.assign(...days.map(day => (createValue(day, input))))
            },
        }

        const result = await update({variables});

        if(result){
            await refetch();
            showFlashMessage(t('global.messages.profileSaved'))
        }
    }, []);

    if(data && data.doctorProfile){
        return (
            <>
                <TitleBar title={t('global.doctorProfile')} />
                <Form<DoctorFormValues>
                    initialValues={{
                        office: data.doctorProfile.office,
                        category: data.doctorProfile.category,
                        city: data.doctorProfile.city,
                        // @ts-ignore
                        ...Object.assign(...days.map(day => createInitValue(day, data.doctorProfile)))
                    }}
                    onSubmit={handleSubmit}
                    >
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <BoxGrid2>
                                <div>
                                    <InputField label={t('global.office')} name='office' showLabel={true} />
                                    <InputField label={t('global.category')} name='category' showLabel={true} />
                                    <InputField label={t('global.city')} name='city' showLabel={true} />
                                </div>
                                <div>
                                    {days.map(day => (
                                      <OpeningHourField label={t(`global.days.long.${day.substr(0,2)}`)} day={day}/>
                                    ))}
                                </div>
                            </BoxGrid2>
                            <Button>{t('global.save')}</Button>
                        </form>
                    )}
                </Form>
            </>
        )
    } else {
        return null;
    }
}

export default DoctorForm;
