import React, {FC, InputHTMLAttributes} from 'react';
import {useField} from "react-final-form";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import Icon from './../../../icons/calendar.svg';
import moment from "moment";

type DateTimeFieldProps = {
    name: string;
    onlyFuture?: boolean
};

export const Container = styled.div`
  position: relative;
  margin-bottom: 10px;
`

export const Result = styled.div`
  background: rgba(235, 240, 241, 0.26);
  color: white;
  border-radius: 25px;
  height: 39px;
  line-height: 39px;
  padding-left: 20px;
  padding-right: 20px;
`

export const DateInput = styled.input`
    //display: none;
  background: #EBF0F1;
  border: none;
  border-radius: 25px;
  height: 39px;
  color: #062443;
  width: calc(100% - 40px);
  padding-left: 20px;
  padding-right: 20px;
`

export const CalendarIcon = styled.img`
  margin-right: 0;
`

const DateTimeField: FC<DateTimeFieldProps> = ({
    name,onlyFuture = true
                                               }) => {

    const { t } = useTranslation();
    const { input, meta } = useField(name, { type: 'datetime-local',  });

    if(onlyFuture){
        input.min = moment().format('YYYY-MM-DDTHH:MM');
    }

    return (
        <Container>
            <DateInput {...input}/>
            {/*<Result onClick={() => {}}>*/}
            {/*    Ihned <CalendarIcon src={Icon}/>*/}
            {/*</Result>*/}
        </Container>
    )
}

export default DateTimeField;
