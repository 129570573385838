import React, {FC} from 'react';
import styled from "styled-components";
import {Call, CallDetail} from "../types";
import Moment from "react-moment";
import UserCircle from "../../user/components/UserCircle";
import {twoChars} from "../../../tools/twoChars";
import createAddToCalendarPayload from "../../../tools/calendarPayload";
import moment, {duration} from "moment";
import { margin, MarginProps } from "styled-system";

const BoxWrapper = styled.div`
    background-color: white;
    box-shadow: 0 2px 5px rgba(226, 235, 237, 0.61);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
`

const Link = styled.a`
    color: #1b5a9c;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`

const BoxInner = styled.div`
    flex: 1 1 auto;
    padding: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

type CallBoxProps = {
    callDetail: CallDetail
    onClick?: (id: string) => void
}

const Item = styled.div<MarginProps>`
    margin-bottom: 10px;
    ${margin}
`

const SecondText = styled.span`
  color: #9BA7B3;
`

const CallBox: FC<CallBoxProps> = ({onClick, callDetail}) => {

    const handleClick = () => {
        if (onClick) {
            onClick(callDetail.call.id);
        }
    }

    const calendarData = createAddToCalendarPayload({
        title: `Hovor s ${callDetail.opponent?.fullName}`,
        start: new Date(callDetail.call.started),
        end: moment(callDetail.call.started).add(15, 'minutes').toDate()
    });

    const showCalendarLink = new Date(callDetail.call.started) > new Date();

    return (
        <BoxWrapper onClick={handleClick}>
            <BoxInner>
                <Item><UserCircle variant={'big'}
                                  twoChars={twoChars([callDetail.opponent.firstName, callDetail.opponent.lastName])}
                                  profilePicture={callDetail.opponent?.profilePicture}/></Item>
                <Item>{callDetail.opponent?.fullName}</Item>
                <Item mt={'auto'}><SecondText><Moment>{callDetail.call.started}</Moment></SecondText></Item>
                {showCalendarLink && <Link target="_blank" href={calendarData}>.ics pro kalendář</Link>}
                {callDetail.call.duration && duration(callDetail.call.duration, 'milliseconds').format()}
            </BoxInner>
        </BoxWrapper>
    );
}
export default CallBox;
