import React, {FC, ReactNode} from 'react';
import styled from 'styled-components'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 184px auto;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  //grid-row-gap: 0px;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 32px;
  }
`

const InfoColumn = styled.div`
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-gap: 32px;
  justify-content: space-between;
`

const MainColumn = styled.div`
    position: relative;
`


type CallLayoutProps = {
    leftInfoBar: ReactNode
}

const CallLayout: FC<CallLayoutProps> = ({leftInfoBar, children}) => {

    return (
        <Grid>
            <InfoColumn>{leftInfoBar}</InfoColumn>
            <MainColumn>{children}</MainColumn>
        </Grid>
    )
}

export default CallLayout;
