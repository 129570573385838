import React, {FC, useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import TitleBar from "../../primitives/TitleBar";
import {useMutation, useQuery} from "@apollo/client";
import {FindDoctors, FindDoctorsQuery} from "../../../module/user/graphql";
import {BoxGridLayout} from '../../layout/boxGridLayout';
import UserBox from "../../../module/user/components/UserBox";
import {CreateCall, CreateCallResult, PlannedCallsQuery, PlannedCallsResult} from "../../../module/call/graphql";
import {useHistory} from "react-router";
import {showFlashMessage} from "../../FlashMessages";
import InvitationPanel from "../../../module/user/components/InvitationPanel";
import Plus from "../../icons/plus.svg";
import Button from "../../primitives/Button";
import CallBox from "../../../module/call/components/CallBox";

const PatientDashboard: FC = () => {
    const history = useHistory();
    const {t} = useTranslation();

    const [invitationPanelOpened, setInvitationPanelOpened] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const {data: plannedCallsData, loading: plannedCallsLoading, refetch} = useQuery<PlannedCallsResult>(PlannedCallsQuery);

    const {data, loading} = useQuery<FindDoctorsQuery>(FindDoctors, {
        variables: {
            filter: {
                fullText: ''
            }
        }
    });

    const [createCall] = useMutation<CreateCallResult>(CreateCall);

    const handleClick = useCallback(async (userId: string) => {
        if (!disabled) {
            setDisabled(true);

            showFlashMessage(t('global.messages.creatingCall'));

            const result = await createCall({
                variables: {
                    input: {
                        userId
                    }
                }
            });

            setDisabled(false);

            if (result.data?.createCall?.call.id) {
                history.push(`/call/${result.data.createCall.call.id}`);
            } else {
                showFlashMessage(t('global.messages.cantCreateCall'), 'error');
            }
        }
    }, [t, disabled, setDisabled]);

    const closeInvitationPanel = () => {
        setInvitationPanelOpened(false);
        refetch().then();
    }

    return (
        <>
            <TitleBar title={t('global.onlineDoctors')}>
                <Button onClick={() => setInvitationPanelOpened(true)} variant="primary">
                    <img src={Plus} alt=""/>
                    <span>{t('global.planCall')}</span>
                </Button>
            </TitleBar>

            {!plannedCallsLoading && plannedCallsData && plannedCallsData.plannedCalls.length > 0 && (
                <>
                    <TitleBar title={t('global.plannedCalls')}/>
                    <BoxGridLayout>
                        {plannedCallsData.plannedCalls.map(item =>
                            <CallBox key={`planned_call_${item.call.id}`} onClick={(id) => history.push(`/call/${id}`)} callDetail={item}/>
                        )}
                    </BoxGridLayout>
                    <TitleBar title={t('global.onlineDoctors')}/>
                </>
            )}

            {data && data.filterDoctors.length > 0 && (
                <>
                    <BoxGridLayout>
                        {data.filterDoctors.map(user =>
                            <UserBox key={user.id} user={user} onClick={handleClick}/>
                        )}
                    </BoxGridLayout>
                </>
            )}

            {loading && <span>{t('global.loading')}</span>}

            {data && data.filterDoctors.length === 0 && <span>{t('global.noDoctorOnline')}</span>}

            {invitationPanelOpened && <InvitationPanel inviteType={"Doctor"} onClose={closeInvitationPanel}/>}
        </>)
}

export default PatientDashboard;
