import styled from "styled-components";
import {margin, MarginProps, variant} from "styled-system";

export const Wrapper = styled.div`
  height: 64px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  width: 968px;
  display: flex;
  justify-content: space-between;
  grid-gap: 12px;
  padding: 0 10px;
`

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const VerticalDivider = styled.span`
  border-left: 1px solid #1b5a9c;
  opacity: 0.24;
  height: 35px;
  margin-left: 25px;
  margin-right: 25px;
  @media (max-width: 479px) {
    margin-left: 8px;
    margin-right: 8px;
  }
`

type TextVariants = {
    variant?: 'normal'|'bold'
}

export const Text = styled.span<TextVariants & MarginProps>`
  font-size: 13px;
  color: #062443;
  font-weight: 400;
  ${margin}
  ${() => variant({
    variants: {
      normal:{},
      bold:{
          fontWeight: 800
      },
    }
  })}
`
