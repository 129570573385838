import {gql} from "@apollo/client";

export const SubscribeNotifications = gql`
    mutation subscribe($input: SubscribeInput!) {
        subscribe(input: $input)
    }
`

export const TestNotification = gql`
    mutation testNotification {
        testNotification{
            uniqueId
            successful
        }
    }
`

export const UnsubscribeNotification = gql`
    mutation unsubscribe($input: UnsubscribeInput!) {
        unsubscribe(input: $input)
    }
`
