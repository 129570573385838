class AuthStorage {
    private accessToken: string|null = null;
    private refreshToken: string|null = null;

    constructor() {
        try{
            const tokensPlain = localStorage.getItem('auth');
            if(tokensPlain){
                const tokens = JSON.parse(tokensPlain);

                if(tokens.accessToken && tokens.refreshToken){
                    this.accessToken = tokens.accessToken;
                    this.refreshToken = tokens.refreshToken;
                }else{
                    localStorage.removeItem('auth');
                }
            }
        }catch(e){

        }
    }

    public getAccessToken(): string|null {
        return this.accessToken;
    }

    public getRefreshToken(): string|null {
        return this.refreshToken;
    }

    public setAccessToken(accessToken: string, refreshToken: string): void {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;

        try{
            localStorage.setItem('auth', JSON.stringify({
                accessToken,
                refreshToken
            }))
        }catch(e){

        }
    }

    public forgetTokens(): void {
        this.accessToken = null;
        this.refreshToken = null;

        try{
            localStorage.removeItem('auth');
        }catch(e){

        }
    }
}

const authStorage = new AuthStorage();

export default authStorage;
