import moment from "moment";

type CreateEventProps = {
    start: Date;
    end: Date;

    title: string;
    description?: string;

    location?: string;
    url?: string;
}

export default function createAddToCalendarPayload(props: CreateEventProps) : string {
    const start = moment(props.start).format('YYYYMMDDTHHmmss')
    const end = moment(props.end).format('YYYYMMDDTHHmmss')

    const payload = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `DTSTART:${start}`,
        `DTEND:${end}`,
        `SUMMARY:${props.title}`
    ];

    if(props.description) {
        payload.push(`DESCRIPTION:${props.description}`);
    }

    if(props.location) {
        payload.push(`LOCATION:${props.location}`);
    }

    if(props.url) {
        payload.push(`URL:${props.url}`);
    }

    payload.push('END:VEVENT', 'END:VCALENDAR');

    return `data:text/calendar;charset=utf8,${payload.join('\n')}`
}
