import React, { FC } from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {twoChars} from "../../../tools/twoChars";
import UserCircle from "./UserCircle";
import {User} from "../types";

const LinkName = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #062443;
  font-size: 13px;
`

const Name = styled.span`
  margin-right: 10px;
  flex: 0 0 auto;
  font-weight: 500;
`

type Props = {
    user: Pick<User, 'firstName'|'lastName'|'fullName'|'profilePicture'>
}


const UserName: FC<Props> = ({ user }) => {
    return (
        <LinkName to='/profile'>
            <Name>{user.fullName}</Name>
            <UserCircle twoChars={twoChars([user.firstName, user.lastName])} profilePicture={user.profilePicture} />
        </LinkName>
    )
}

export default UserName;
