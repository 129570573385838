import * as React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {configure} from "./tools/context-dev-tools";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './translations'

i18n.use(initReactI18next)
    .init({
        resources: {
            ...translations
        },
        lng: 'cs',
        fallbackLng: 'cs',

        interpolation: {
            escapeValue: false,
        },
    })

const container = document.getElementById("root");

if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>,
    container
);

configure(container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
