import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import authStorage from "./authStorage";
import {useConfiguration} from "../configuration/useConfiguration";

const conf = useConfiguration()

const targetEndPointLink = createHttpLink({
    uri: conf.coreApi,
})

const authLink = setContext((_, {headers}) => {
    const authToken = authStorage.getAccessToken();
    return {
        authToken,
        headers: {
            ...headers,
            authorization: authToken ? `Bearer ${authToken}` : null,
        }
    }
});

export const CoreApolloLink = new ApolloClient({
    link: ApolloLink.from([
        authLink,
        targetEndPointLink
    ]),
    cache: new InMemoryCache(),
})
