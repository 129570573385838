import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {Prompt, useHistory, useParams} from "react-router";
import styled from "styled-components";
import { CallSignalization } from "../module/call/CallSignalization";
import TitleBar from "../components/primitives/TitleBar";
import { useTranslation } from "react-i18next";
import ToggleButton from "../components/primitives/ToggleButton";
import Button from "../components/primitives/Button";
import { ReactComponent as Camera } from "../components/icons/camera.svg";
import { ReactComponent as Microphone } from "../components/icons/microphone.svg";
import Call from "../components/icons/call.svg";
import {useQuery} from "@apollo/client";
import {CallDetailQuery, CallDetailResult} from "../module/call/graphql";
import CallLayout from "../module/call/components/callLayout";
import UserBox from "../module/user/components/UserBox";
import InfoBox from "../module/call/components/InfoBox";
import OpeningHours from "../module/user/components/OpeningHours";
import PoweredLogo from "../components/primitives/PoweredLogo";
import {useConfiguration} from "../configuration/useConfiguration";
import AuthStorage from "../apollo/authStorage";
import useUnload from "../tools/useUnload";
import {useCall} from "../module/call/useCall";

type RemoteVideoProps = {
  showOverlay: boolean;
};

const RemoteVideo = styled.video<RemoteVideoProps>`
  //aspect-ratio: 4 / 3;
  height: auto;
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 20px 60px #cddbde;
  background-color: ${(props) => (props.showOverlay? 'rgba(0,0,0,0.3)' : '')};
  filter: ${(props) => (props.showOverlay? 'blur(1.5rem)' : 'none')};
`;

const OwnVideo = styled.video`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 184px;
  max-width: 25%;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  @media (max-width: 767px) {
    top: 10px;
    right: 10px;
  }
`;

const StatusText = styled.p`
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  color: black;
  font-weight: bold;
  font-size: 20px;
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 16px;
`;

type CallPageParams = {
  callId: string;
};

const formatTime = (seconds: number): string => {
  const format = (val: number) => `0${Math.floor(val)}`.slice(-2);

  const hours = seconds / 3600;
  const minutes = (seconds % 3600) / 60;

  return [hours, minutes, seconds % 60].map(format).join(":");
};

const CallPage: FC = () => {
  const { callWS } = useConfiguration();
  const history = useHistory();
  const { t } = useTranslation();
  const { callId } = useParams<CallPageParams>();

  useUnload((e) => {
    e.preventDefault();
    console.log('Use unload');
    const message = t("global.reallyEndCall");
    e.returnValue = message;
    return message;
  });

  const { data, loading } = useQuery<CallDetailResult>(CallDetailQuery, {
    variables: {
      id: callId,
    },
  });

  const remoteVideo = useRef<HTMLVideoElement>(null);
  const localVideo = useRef<HTMLVideoElement>(null);

  const [timer, setTimer] = useState<number|null>(null);
  const [timerStarted, setTimerStarted] = useState<Date>(new Date());
  const [duration, setDuration] = useState(0);

  const [canLeave, setCanLeave] = useState(false);
  const [canStart, setCanStart] = useState(false);
  const [status, setStatus] = useState<string | null>("enableCameraAndMicrophone");
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [callSignalization,setCallSignalization] = useState<CallSignalization | null>(null);

  const startTimer = useCallback(() => {
    console.log('setTimer', {timer});
    if(null === timer) {
      setDuration(0);
      setTimerStarted(new Date());

      const interval = setInterval(() => {
        const d = ((new Date()).getTime() - timerStarted.getTime()) / 1000
        setDuration(d)
      }, 500);

      console.log(interval);

      setTimer(interval);
    }
  }, [timer]);

  const endTimer = useCallback(() => {
    console.log('ending timer', {timer});
    if (timer) {
      clearInterval(timer);
    }
  }, [timer]);

  const closeCall = useCallback(async () => {
    endTimer();

    if (callSignalization) {
      await callSignalization.endCall();
    }

    setCallSignalization(null);

    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
        mediaStream.removeTrack(track);
      });
    }
  }, [callSignalization, mediaStream, callId])

  useEffect(() => {
    if(data && data.callDetail){
      setCanStart(data.callDetail.canBeStarted);
      if(data.callDetail.reason){
        setStatus(data.callDetail.reason);
      }
    }
  }, [data])

  useEffect(() => {
    if(canStart && callSignalization === null && mediaStream){
      setStatus("callStarting");

      const authToken = AuthStorage.getAccessToken();

      if(authToken) {
        const signalization = new CallSignalization(
            callWS,
            callId,
            authToken,
            mediaStream,
            (mediaStream: MediaStream): void => {
              if (remoteVideo.current) {
                setStatus(null);
                remoteVideo.current.srcObject = mediaStream;
                startTimer();
              }
            },
            (newStatus: string) => {
              setCanLeave(true);
              setStatus(newStatus);
              endTimer();
            }
        );

        setCallSignalization(signalization);
      } else {
        history.push('/login')
      }
    }
  }, [canStart, mediaStream])

  useEffect(() => {
    const tryGetMediaStream = async () => {
      let counter = 0;

      do {
        try {
          const newMediaStream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
          });

          setMediaStream(newMediaStream);

          return;
        } catch (e) {
          // alert(
          //   "Prosím povolte použití kamery a mikrofonu, jinak hovor nemůže proběhnout.",
          // );
        }
        counter += 1;
      } while (counter < 10);

      setStatus("cantStart");
      history.push("/");
    };

    tryGetMediaStream().then();

    return () => {
      closeCall().then();
    }
  }, []);

  useEffect(() => {
    if (mediaStream && localVideo.current) {
      localVideo.current.srcObject = mediaStream;
      setStatus(data ? data.callDetail.reason : null);
    }
  }, [localVideo, mediaStream]);

  const endCall = useCallback(async () => {
    if (!canLeave) {
      if(window.confirm(t("global.reallyEndCall"))){
        await closeCall();
        await history.push("/");
      }
    }else{
      await history.push("/");
    }
  }, [history, closeCall, canLeave]);

  if (loading) {
    return <span>Loading</span>;
  }

  if (!data) {
    return <span>Error</span>;
  }

  const infoBar = (
    <>
      <UserBox user={data.callDetail.opponent} />
      {data.callDetail.opponent.doctor && (
        <InfoBoxWrapper>
          <InfoBox
            title={t("global.office")}
            content={data.callDetail.opponent.doctor.office}
          />
          <InfoBox
            title={t("global.openingHours")}
            content={<OpeningHours {...data.callDetail.opponent.doctor} />}
          />
        </InfoBoxWrapper>
      )}
    </>
  );

  return (
    <>
      <Prompt when={!canLeave && callSignalization !== null} message={t("global.reallyEndCall")} />
      <TitleBar title={t("global.ongoingCall")} subTitle={formatTime(duration)}>
        <ToggleButton initValue={true}
          change={(val) => callSignalization && callSignalization.setAudioEnabled(!val)}
        >
          <Microphone />
        </ToggleButton>
        <ToggleButton initValue={true}
                      change={(val) => callSignalization && callSignalization.setVideoEnable(!val)}
        >
          <Camera />
        </ToggleButton>

        <Button onClick={endCall} variant="danger">
          <img src={Call} alt="" /> {t("global.hangOut")}
        </Button>
      </TitleBar>

      <CallLayout leftInfoBar={infoBar}>
        <RemoteVideo
          ref={remoteVideo}
          autoPlay={true}
          playsInline={true}
          showOverlay={!!status}
        />
        <OwnVideo
          ref={localVideo}
          autoPlay={true}
          playsInline={true}
          muted={true}
        />

        {status && <StatusText>{t(`global.callStatus.${status}`)}</StatusText>}

      </CallLayout>
      {/*<PoweredLogo />*/}
    </>
  );
};

export default CallPage;
