import React, {FC, useCallback, useState} from 'react';
import {Form} from "react-final-form";
import InputField from "../../../components/primitives/Form/InputField";
import {useTranslation} from "react-i18next";
import Button from "../../../components/primitives/Form/Button";
import ButtonSample from "../../../components/primitives/ButtonSample";
import {useMutation} from "@apollo/client";
import {LoginAction, LoginActionResult} from "../graphql";
import {useHistory} from "react-router";
import AuthStorage from "../../../apollo/authStorage";
import {ErrorMessage} from "../../../components/primitives/errorMessage";
import {BoxFlex2} from "../../../components/layout/boxFlex2";
import styled from "styled-components";

type LoginFormValues = {
    email: string;
    password: string;
}

const LoginForm: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loginError,setLoginError] = useState<string|null>(null);
    const [loginAction] = useMutation<LoginActionResult>(LoginAction);

    const handleSubmit = useCallback(async (input: LoginFormValues) => {
        setLoginError(null);
        try{
            const result = await loginAction({ variables: { input } });

            if(result.data){
                AuthStorage.setAccessToken(result.data.login.accessToken, result.data.login.refreshToken);

                history.push('/');
            }
        }catch(e){
            setLoginError(t('global.badUsernameOrPassword'))
        }
    }, []);

    return (
        <Form<LoginFormValues>
            initialValues={{
                email: '',
                password: '',
            }}
            onSubmit={handleSubmit}>
            {(props) => (
                <form onSubmit={props.handleSubmit}>
                    <InputField label={t('global.email')} name='email' />
                    <InputField label={t('global.password')} name='password' type='password'  />
                    {loginError && <ErrorMessage>{loginError}</ErrorMessage>}
                    <BoxFlex2 mt={'32px'}>
                        <Button>{t('global.login')}</Button>
                        {/*<ButtonSample>{t('global.resetPassword')}</ButtonSample>*/}
                    </BoxFlex2>
                </form>
            )}
        </Form>)
}

export default LoginForm;
