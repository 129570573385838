import styled from "styled-components";

export const LayoutWrapper = styled.div`
    background-color: #F7F7F7;
    min-height: calc(100vh - 64px);
    display: flex;  
    justify-content: center;
    padding-bottom: 20px;
`

export const Content = styled.div`
  width: 968px;
  padding: 0 10px;
`
