import styled from "styled-components";

export const Title = styled.h2`

`

export const Text = styled.p`
  margin-bottom: 20px;
`

export const Divider = styled.hr`
  border: 1px solid rgba(255, 255, 255, 0.55);
  opacity: 0.36;
  margin-bottom: 20px;
`

export const BottomPart = styled.div`
  width: 100%;  
  padding-bottom: 35px;
`
