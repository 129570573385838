import React, {FC, useCallback} from "react";
import TitleBar from "../../primitives/TitleBar";
import {useTranslation} from "react-i18next";
import ProfileForm from "../../../module/user/form/ProfileForm";
import DoctorForm from "../../../module/user/form/DoctorForm";
import Button from "../../primitives/Button";
import {useMutation} from "@apollo/client";
import {TestNotification} from "../../../module/notifications/graphql";
import {useHistory} from "react-router";
import styled from "styled-components";


const Profile: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [testNotification] = useMutation(TestNotification);

    const handleTest = useCallback(async () => {
        await testNotification();
    }, []);

    const handleLogout = useCallback(async () => {
        history.push('/logout')
    }, [history]);

    return (
        <>
            <TitleBar title={t('global.profile')}>
                <Button onClick={handleTest} variant='primary'> {t('global.testNotification')}</Button>
                <Button onClick={handleLogout} variant='danger'>{t('global.logout')}</Button>
            </TitleBar>

            <ProfileForm />
            <DoctorForm />
        </>
    )
}

export default Profile;
